#footer {
  padding-bottom: 0;
  background: url('/img/bg-theme5.png') #cecece;
}
#footer .footer_white {
  background: #FFFFFF;
}
#footer .container_pause {
  display: block;
  height: 15px;
}
#footer ul.row {
  position: static;
}
#footer ul li a {
  font-weight: 600;
}
#footer .row {
  position: relative;
}
#footer a {
  color: #212121;
}
#footer a:hover {
  color: #212121;
  text-decoration: underline;
}
#footer h3 a {
  color: #FFFFFF;
}
#footer .row {
  background: transparent;
}
#footer .row_black {
  margin: 0px -15px 0px -15px;
}
#footer #footer-informacje {
  background-color: #212121;
  overflow: hidden;
  padding-bottom: 15px;
  clear: both;
}
#footer #footer-informacje #footer-newsletter {
  padding-top: 15px;
}
#footer #footer-informacje #footer-newsletter label > span {
  display: inline-block;
  float: left;
  line-height: 40px;
  padding-right: 4px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 17px;
}
#footer #footer-informacje #footer-newsletter #newsletter_form {
  height: 65px; 
  overflow: hidden;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset {
  position: relative;
  margin: 0px;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset label {
  display: inline-block;
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group {
  float: none;
  height: 80px;
  clear: both;
  width: 100%;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group.checkbox {
  display: inline-block;
  float: left;
  margin: 5px 0 5px 0;
  padding: 0;
  min-height: 15px;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group.radio {
  display: inline-block;
  float: left;
  margin: 5px 0 5px 0;
  padding: 0;
  min-height: 15px;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group > input {
  display: inline-block;
  float: left;
  margin: 0px 0px 0px 0px;
  border: 1px solid #212121;
  padding: 10px 70px 10px 12px;
  width: 100%;
  height: 40px;
  max-width: 272px;
  font-size: 13px;
  background: #3c3c3c;
  color: #FFFFFF;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group > textarea {
  display: inline-block;
  float: left;
  border: 1px solid #212121;
  padding: 10px 70px 10px 12px;
  width: 100%;
  height: 40px;
  max-width: 272px;
  background: #3c3c3c;
  color: #FFFFFF;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group > div {
  display: inline-block;
  float: left;
  border: 1px solid #212121;
  padding: 10px 70px 10px 12px;
  width: 100%;
  height: 40px;
  max-width: 272px;
  background: #3c3c3c;
  color: #FFFFFF;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group > select {
  display: inline-block;
  float: left;
  border: 1px solid #212121;
  padding: 10px 70px 10px 12px;
  width: 100%;
  height: 40px;
  max-width: 272px;
  background: #3c3c3c;
  color: #FFFFFF;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group > .ajax_blad {
  margin: 2px 0px 0px 0px;
  clear: both;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset button {
  margin: 2px 0px 0px -68px;
  background-color: #969696;
  border: 1px solid #5e5e5e;
  color: #212121;
  text-transform: uppercase;
  font-size: 16px;
  padding: 9px 6px 10px 5px;
  overflow: hidden;
  font-weight: 600;
  line-height: 16px;
}
#footer #footer-informacje #footer-newsletter #newsletter_form fieldset button:hover {
  color: #FFFFFF;
}
#footer #footer-informacje #footer-access {
  min-height: 78px;
  margin-bottom: 5px;
}
#footer #footer-informacje #footer-access > p {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
  font-size: 12px;
  color: #ABABAB;
  text-shadow: 1px 1px 1px #3c3c3c;
  overflow: hidden;
}
#footer #footer-informacje #footer-access > p:first-child {
  color: #FFFFFF;
  font-size: 13px;
  margin: 0px;
  padding-top: 10px;
}
#footer #footer-informacje #footer-access > p i {
  float: left;
  margin-top: 4px;
  color: #ABABAB;
  font-size: 26px;
  margin-right: 9px;
}
#footer #footer-informacje #footer-access > p i.icon-certificate:before {
  content: "\f0a3";
}
#footer #footer-informacje #footer-access > p i.icon-percent:before {
  content: "\f295";
}
#footer #footer-informacje #footer-access > p i.icon-paint-brush:before {
  content: "\f1fc";
}
#footer #footer-informacje #footer-access > p i.icon-file-text-o:before {
  content: "\f0f6";
}
#footer #footer-informacje .footer-row > p {
  color: #c9c9c9;
}
#footer #footer-informacje .footer-row #footer-facebook {
  margin-bottom: 10px;
  display: inline-block;
}
#footer #footer-informacje .footer-row:last-child {
  padding-top: 40px;
}
#footer #footer-informacje .footer-row img {
  max-width: 100%;
}
#footer #footer-informacje .footer-row section {
  padding-left: 0;
  padding-right: 0;
}
#footer #footer-informacje .footer-row section ul li a {
  color: #ABABAB !important;
}
#footer #footer-informacje .footer-row section ul li a:hover {
  color: #FFFFFF !important;
}
#footer #footer-informacje .footer-row footer-p-map {
  color: #FFFFFF;
}
#footer #footer-informacje .footer-row #footer-map {
  display: inline-block;
}
#footer #footer-informacje .footer-row > div:last-child {
  margin-top: 30px;
}
#footer #footer-informacje .footer-row h3 {
  margin: 15px 2px 10px 0px;
  font-size: 14px;
  line-height: 1.1;
  color: #FFFFFF;
}
#footer #footer-informacje .footer-row h4 {
  color: #7f7f7f;
  font-weight: 600;
}
#footer #footer-informacje .footer-row ul {
  padding: 0;
  list-style: none;
}
#footer #footer-informacje .footer-row ul li {
  line-height: 16px;
}
#footer #footer-informacje .footer-row ul li a {
  color: #ABABAB;
  font-size: 12px;
  text-shadow: 1px 1px 1px #3c3c3c;
}
#footer #footer-informacje .footer-row ul li a:hover {
  text-decoration: none;
  color: #FFFFFF;
}
#footer #footer-informacje .footer-row #footer-list li {
  color: #c9c9c9;
  font-size: 12px;
  line-height: 20px;
}
#footer #footer-informacje .footer-row #footer-list li i {
  margin-right: 5px;
  font-size: 12px;
}
#footer #footer-informacje .footer-row #footer-list li i.icon-map-marker:before {
  content: "\f041";
}
#footer #footer-informacje .footer-row #footer-list li i.icon-phone:before {
  content: "\f095";
}
#footer #footer-informacje .footer-row #footer-list li i.icon-envelope-o:before {
  content: "\f003";
}
#footer #footer-informacje .footer-row #footer-list li a {
  color: #FFFFFF;
}
#footer #footer-informacje .footer-row #footer-p-map {
  margin: 15px 2px 10px 0px;
  font-size: 14px;
  color: #FFFFFF;
}
#footer #cookieNotice {
  width: 100%;
  position: fixed;
  bottom: 0px;
  background: #3B3B3B;
  z-index: 38;
  font-size: 12px;
  line-height: 1.3em;
  font-family: 'Open Sans';
  left: 0px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
}
#footer #cookieNotice p {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
#footer #cookieNotice #cookieNoticeContent {
  display: block;
  position: relative; 
  margin: auto; 
  width: 100%; 
}
#footer #cookieNotice #cookieNoticeContent a {
  color: #FFFFFF;
}
@media (max-width: 768px) {
  #footer {
	background: transparent;
  }
  #footer #footer-information {
	display: none;
  }
  #footer .container_pause {
    background: url('/img/bg-theme5.png') #cecece;
  }
  #footer h3 {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 13px;
  }
  #footer h3:after {
    display: block;
    content: "\f055";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 1px;
  }
  #footer h3.active:after {
    content: "\f056";
  }
  #footer .row_black {
    background: transparent;
  }
  #footer #footer-informacje #footer-access > p {
    padding-top: 4px;
  }
  #footer #footer-informacje #footer-access > p i {
    margin-top: -4px;
  }
  #footer #cookieNotice #cookieNoticeContent table td {
    display: block;
    width: auto !important;
    text-align: center !important;
  }
}
@media (max-width: 413px) {
  #footer #footer-informacje #footer-newsletter #newsletter_form {
    height: 102px; 
  }
  #footer #footer-informacje #footer-newsletter #newsletter_form fieldset .form-group {
    height: 116px;
  }
  #footer #footer-informacje #footer-newsletter #newsletter_form fieldset button {
    position: absolute;
    top: 5px;
    left: 362px; 
  }
}
@media (max-width: 409px) {
  #footer #footer-informacje #footer-newsletter #newsletter_form fieldset button {
    position: static;
	float: right;
  }
}