#footer #blog-posts {
  margin: 0px -15px;
  padding: 0px 15px;
  background-color: #ffffff;
  height: 100px;
}
#footer #blog-posts .blog-posts-h2 {
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 10px 0px;
  color: #212121;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}
#footer #blog-posts #blog-posts-items article .center-img {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  height: 193px;
  width: 100%;
  background-size: cover;
  background-position: center 80%;
  color: #212121;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
#footer #blog-posts #blog-posts-items article .center-img h3 {
  position: absolute;
  bottom: 0;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 14px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 35px;
}
#footer #blog-posts #blog-posts-items article a {
  display: block;
  overflow: hidden;
  color: #212121;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.1;
  height: 30px;
  text-shadow: 0px 0px 0 rgba(0, 0, 0, 0);
}
#footer #blog-posts #blog-posts-items article a:hover {
  text-decoration: underline;
}
#footer #blog-posts #blog-posts-items p {
  font-size: 10px;
  margin: 0px 0px 0px 0px;
}
@media (max-width: 1040px) {
  #footer #blog-posts #blog-posts-items article .center-img {
    height: 265px;
  }
}
@media (max-width: 992px) {
  #footer #blog-posts #blog-posts-items p {
    line-height: 15px;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  #footer #blog-posts #blog-posts-items article .center-img {
    height: 209px;
  }
}
@media (max-width: 496px) {
  #footer #blog-posts #blog-posts-items article .center-img {
    height: 261px;
  }
}
@media (max-width: 420px) {
  #footer #blog-posts .blog-posts-h2 {
    font-size: 17px;
  }
}